import React, { useState } from 'react'
import TextInput from '../../../../components/Inputs/TextInput'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import FileInput from '../../../../components/Inputs/FileInput'
import { getPassportTypeTranslate } from '../../../../hooks/useGetPassportTypeTranslate'
import IdentityExist from '../../../../components/IdentityExist'

export default function GermanyForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      {getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт' ? (
        <>
          <div className="col-3 col-xs-12">
            {/* PASSPORT INTERNETIONAL NUMBER */}
            <TextInput
              testName="passportInternationalNumber"
              testErrorName="error-passportInternationalNumber"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.documentNumberUkr}
              onChange={passportInternationalNumber => {
                dispatch({
                  type: actions.PASSPORT_INTERNATIONAL_NUMBER,
                  data: passportInternationalNumber,
                })
              }}
              value={state.passportInternationalNumber}
              required
              valid={
                passportDigits(state.passportInternationalNumber) &&
                !invalidFields.includes('passportInternationalNumber')
              }
              onBlur={() => setFocus('')}
              mask={'aa 999999'}
            />
          </div>
          <IdentityExist
            state={state}
            accountForm={accountForm}
            lang={lang}
            dispatch={dispatch}
            show={!state.isIdentityOk}
          />
          <div className="col-2 col-xs-12">
            {/* PASSPORT ISSUE DATE */}
            <DatePicker
              testName="passportIAt"
              testErrorName="error-passportIAt"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportIAt"
              onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
              value={state.passportIAt}
              required
              valid={
                !invalidFields.includes('passportIAt') &&
                validateDate({
                  current: state.passportIAt,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              curr={new Date()}
              min={
                new Date(new Date().setFullYear(new Date().getFullYear() - 10))
              }
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* PASSPORT ISSUED BY */}
            <TextInput
              testName="passportIssuedBy"
              testErrorName="error-passportIssuedBy"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT EXPIRY DATE */}
            <DatePicker
              testName="passportExpiryDate"
              testErrorName="error-passportExpiryDate"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportExpiryDate"
              onChange={data =>
                dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
              }
              value={state.passportExpiryDate}
              required
              valid={
                !invalidFields.includes('passportExpiryDate') &&
                validateDate({
                  current: state.passportExpiryDate,
                  min: new Date(),
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date()}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT DATE BOARDING*/}
            <DatePicker
              testName="passportDateBoardCrossing"
              testErrorName="error-passportDateBoardCrossing"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateBoardCrossing"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING,
                  data,
                })
              }
              value={state.passportDateBoardCrossing}
              required
              valid={
                !invalidFields.includes('passportDateBoardCrossing') &&
                validateDate({
                  current: state.passportDateBoardCrossing,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="mainImageFile"
              testErrorName="error-mainImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.internationalFile}
              onChange={mainImageFile => {
                dispatch({
                  type: actions.MAIN_IMAGE_FILE,
                  data: mainImageFile,
                })
              }}
              value={state.mainImageFile[state.mainImageFile.length - 1]}
              valid={!invalidFields.includes('mainImageFile')}
              documentType="Passport1"
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="addressImageFile"
              testErrorName="error-addressImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.arrivalStampFile}
              onChange={addressImageFile => {
                dispatch({
                  type: actions.ADDRESS_IMAGE_FILE,
                  data: addressImageFile,
                })
              }}
              value={state.addressImageFile[state.addressImageFile.length - 1]}
              valid={!invalidFields.includes('addressImageFile')}
              documentType="Passport2"
            />
          </div>
          <div className="col-4 col-xs-12">
            {/* PASSPORT_PERSON_NUMBER */}
            <TextInput
              testName="passportPersonNumber"
              testErrorName="error-passportPersonNumber"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.passportIinNumber}
              onChange={passportPersonNumber => {
                dispatch({
                  type: actions.PASSPORT_PERSON_NUMBER,
                  data: passportPersonNumber,
                })
              }}
              value={state.passportPersonNumber}
              required
              valid={!invalidFields.includes('passportPersonNumber')}
              onBlur={() => setFocus('')}
              mask={'999999999999'}
            />
          </div>
          <div
            className={
              invalidFields.includes('passportDateBoardCheck')
                ? 'col-12 dateBoardCheckBox invalid'
                : 'col-12 dateBoardCheckBox'
            }
          >
            <input
              data-test-id="passportDateBoardCheck"
              id="passportDateBoardCheck"
              name="passportDateBoardCheck"
              type="checkBox"
              onChange={() =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING_CHECK,
                  data: !state.passportDateBoardCheck,
                })
              }
              checked={state.passportDateBoardCheck ?? false}
            />
            <label htmlFor="passportDateBoardCheck">
              {accountForm.passportDateBoardCheck30.label[lang]}
            </label>
          </div>
        </>
      ) : getPassportTypeTranslate(+state.passportType) ===
        'Вид на жительство' ? (
        <>
          <div className="col-2 col-xs-12">
            {/* PASSPORT PERMANENT RESIDENCY DATE */}
            <DatePicker
              testName="passportDateIssuePermanentResidency"
              testErrorName="error-passportDateIssuePermanentResidency"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateIssuePermanentResidency"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY,
                  data,
                })
              }
              value={state.passportDateIssuePermanentResidency}
              required
              valid={
                !invalidFields.includes(
                  'passportDateIssuePermanentResidency'
                ) &&
                validateDate({
                  current: state.passportDateIssuePermanentResidency,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              min={
                state.birthDate
                  ? new Date(
                      new Date(state.birthDate).setFullYear(
                        new Date(state.birthDate).getFullYear()
                      )
                    )
                  : new Date(
                      new Date().setFullYear(new Date().getFullYear() - 67)
                    )
              }
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* ISSUED BY */}
            <TextInput
              testName="passportIssuedBy"
              testErrorName="error-passportIssuedByy"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* PASSPORT_PERSON_NUMBER */}
            <TextInput
              testName="passportPersonNumber"
              testErrorName="error-passportPersonNumber"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.passportIinNumber}
              onChange={passportPersonNumber => {
                dispatch({
                  type: actions.PASSPORT_PERSON_NUMBER,
                  data: passportPersonNumber,
                })
              }}
              value={state.passportPersonNumber}
              required
              valid={!invalidFields.includes('passportPersonNumber')}
              onBlur={() => setFocus('')}
              mask={'999999999999'}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="residenceImageFile"
              testErrorName="error-residenceImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.residenceFile}
              onChange={residenceImageFile => {
                dispatch({
                  type: actions.RESIDENCE_IMAGE_FILE,
                  data: residenceImageFile,
                })
              }}
              value={
                state.residenceImageFile[state.residenceImageFile.length - 1]
              }
              valid={!invalidFields.includes('residenceImageFile')}
              documentType="Passport1"
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="addressImageFile"
              testErrorName="error-addressImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.residenceBacksideFile}
              onChange={data => {
                dispatch({
                  type: actions.ADDRESS_IMAGE_FILE,
                  data: data,
                })
              }}
              value={state.addressImageFile[state.addressImageFile.length - 1]}
              valid={!invalidFields.includes('addressImageFile')}
              documentType="Passport2"
            />
          </div>
        </>
      ) : null}
    </>
  )
}
