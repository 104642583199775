import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import AccountForm from '../types/account-form.types'
import Agreement from './Agreement'
import { Lang, AllMarkdownRemark, Frontmatter } from '../types/common-types'
import { State, Dispatch, actions } from '../state-actions-reducers'
import AcceptConditionsImage from '../images/anketa_img_10.svg'

import {
  profileType_Physical,
  profileType_Fiscal,
} from '../state-actions-reducers'

import styles from './AcceptConditions.module.scss'
import { INSTANCE } from '../server-parameters'

interface Props {
  lang: Lang
  accountForm: AccountForm
  state: State
  dispatch: Dispatch
  onAccept: () => void
}

interface AgreementData extends Frontmatter {
  html: string
}

export default function AcceptConditions({
  lang,
  accountForm,
  state,
  dispatch,
  onAccept,
}: Props) {
  const result = useStaticQuery<AllMarkdownRemark>(graphql`
    {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            html
            frontmatter {
              path
              type
              title
              instance
              lang
            }
          }
        }
      }
    }
  `)

  const agreements = result.allMarkdownRemark.edges.reduce(
    (agreementsList: AgreementData[], { node }) => {
      console.log(node)
      console.log('instance', INSTANCE)
      if (node.frontmatter.lang !== lang || node.frontmatter.instance !== INSTANCE || node.frontmatter.instance !== INSTANCE) {
        return agreementsList
      }
      console.log('node', node)
      return [...agreementsList, { ...node.frontmatter, html: node.html }]
    },
    []
  )

  const [accept, setAccept] = useState(state.agreedCheck)
  const [agreementToShow, setAgreementToShow] = useState<AgreementData | null>()
  const [hilightUnread, setHilightUnread] = useState(false)

  const [profileType, setProfileTypeIsFiscal] = useState(
    state.profileType == profileType_Fiscal
  )
  const [HilightprofileType, setHilightprofileType] = useState(false)

  const header = accountForm.agreementHeader[lang]
  const description = accountForm.agreementsDescription[lang]
  const confurmLabel = accountForm.agreementButtonConfurmLabel[lang]

  //const confurmButton = "gjllld"
  const declineButton = accountForm.gotoMainPage[lang]
  const buttonTitle = accountForm.agreementShowBtnTip[lang]
  const buttonText = accountForm.agreementShowBtnTipText[lang]

  return (
    <div className="row">
      {!agreementToShow ? (
        <>
          <div className="col-5 col-xs-12">
            <h2 className={styles.header}>{header}</h2>

            {/* <div className={`${styles.buttonsContainer} ${HilightprofileType ? styles.highlighted : ''}`}>

              <button
                onClick={() => {
                  setProfileTypeIsFiscal(false);  
                  dispatch({
                    type: actions.PROFILE_TYPE,
                    data: state.profileType == profileType_Physical ? '': profileType_Physical,
                  })          
                }}
                className={[styles.physicalButton, state.profileType == profileType_Physical ? styles.ButtonHover : ""].join(" ")}
              >
                {accountForm.profileType_PhysicalButton[lang]}
              </button>

              <button
                onClick={() => {
                  setProfileTypeIsFiscal(true);
                  dispatch({
                    type: actions.PROFILE_TYPE,
                    data: state.profileType == profileType_Fiscal ? '': profileType_Fiscal,
                  })
                }}
                className={[styles.fiscalButton, state.profileType == profileType_Fiscal ? styles.ButtonHover : ""].join(" ")}
              >
                {accountForm.profileType_FiscalButton[lang]}
              </button>
            </div> */}
            {
              //state.profileType ?
              <React.Fragment>
                {agreements.length === 1 ? (
                  <AgreementListItem
                    state={state}
                    buttonTitle={buttonTitle}
                    agreement={agreements[0]}
                    hilightUnread={hilightUnread}
                    setAgreementToShow={setAgreementToShow}
                    description={description}
                    buttonText={buttonText}
                  />
                ) : (
                  <>
                    <p>{description}:</p>
                    <ul className={styles.list}>
                      {agreements.map(agreement => {
                        const { type } = agreement
                        return (
                          <li key={type} className={styles.listItem}>
                            <AgreementListItem
                              state={state}
                              buttonTitle={buttonTitle}
                              agreement={agreements[0]}
                              hilightUnread={hilightUnread}
                              setAgreementToShow={setAgreementToShow}
                              buttonText={buttonText}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </>
                )}
                <label className={accept ? styles.checked : styles.label}>
                  <input
                    type="checkbox"
                    checked={state.agreed}
                    className={styles.checkbox}
                    onChange={() => {
                      setAccept(!state.agreedCheck)
                      dispatch({
                        type: actions.AGREED_CHECK,
                        data: !state.agreedCheck,
                      })
                    }}
                  />
                  {confurmLabel}
                </label>
              </React.Fragment>
              //: null
            }
          </div>
          <div
            className={
              styles.AcceptConditionsImageBox + ' ' + 'col-7 col-xs-12'
            }
          >
            <img src={AcceptConditionsImage} alt="image" />
          </div>
        </>
      ) : (
        <Agreement
          html={agreementToShow.html}
          onAccept={() => {
            setAccept(true)
            dispatch({
              type: actions.AGREED_CHECK,
              data: !state.agreedCheck,
            })
            setAgreementToShow(null)
          }}
          onClose={() => {
            setAgreementToShow(null)
          }}
          closeButtonText={accountForm.closeButton[lang]}
          goBackButtonText={accountForm.goBackButton[lang]}
          acceptButtonText={accountForm.acceptButton[lang]}
        />
      )}
    </div>
  )
}

interface AgreementListItemProps {
  state: State
  agreement: AgreementData
  description?: string
  hilightUnread: boolean
  setAgreementToShow: (a: AgreementData) => void
  buttonTitle: string
  buttonText: string
}

function AgreementListItem({
  state,
  agreement,
  hilightUnread,
  description,
  setAgreementToShow,
  buttonTitle,
  buttonText,
}: AgreementListItemProps) {
  const highlight = !state.agreedOn.includes(agreement.type) && hilightUnread
  const descriptionClassName = highlight
    ? `${styles.docDescriptionText} ${styles.highlighted}`
    : styles.docDescriptionText
  return (
    <div>
      <p className={descriptionClassName}>{description}</p>
      <div className={styles.agreementContainer}>
        <span>{buttonText} </span>
        <a
          title={buttonTitle}
          className={styles.showAgreement}
          aria-label={agreement.title}
          onClick={() => setAgreementToShow(agreement)}
        >
          {buttonTitle}
        </a>
      </div>
    </div>
  )
}
