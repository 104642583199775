import React from 'react'

import SelectInput from '../../components/Inputs/SelectInput'
import TextInput from '../../components/Inputs/TextInput'

import {
  validateName,
  validateString,
  stringDigitsLength,
} from '../../validate/validators'
import { Country, ScreenProps } from '../../types/common-types'
import { actions } from '../../state-actions-reducers'
import CompanyScreenOneRus from './Company/RusRegistration/CompanyScreenOne'
import CompanyScreenOneKzt from './Company/KztRegistration/CompanyScreenOne'
import CompanyScreenOneKgz from './Company/KgzRegistration/CompanyScreenOne'
import IpScreenOneRus from './Individual/RusRegistration/IpScreenOne'
import IpScreenOneKzt from './Individual/KztRegistration/IpScreenOne'
import IpScreenOneKgz from './Individual/KgzRegistration/IpScreenOne'
import IpScreenOneRom from './Individual/RomRegistration/IpScreenOne'
import {
  FiscalTypeValues,
  FiscalTypeValuesKz,
  FiscalTypeValuesRom,
  FiscalTypeValuesKgz,
  soleTraderType,
  IpTypeValuesKz,
} from '../../content/fiscalType'
import styles from './FiscalScreenOne.module.scss'
import { getCertificateRegistrationNumber } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationNumber'

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
  allFiscalCountry: Country[]
  addressCountries: Country[]
  countriesList: Country[]
  regionCityRegistered: any
  regionCityFactual: any
  regionCity: any
}

/* REACT COMPONENT */
function FiscalScreenOne({
  lang,
  state,
  initialState,
  dispatch,
  accountForm,
  invalidFields,
  citizenshipCountriesList,
  allFiscalCountry,
  addressCountries,
  regionCityRegistered,
  regionCityFactual,
  countriesList,
  regionCity,
}: Props) {
  console.log('invalidFields', invalidFields)
  return (
    <>
      <main className={styles.main}>
        <div className="row">
          <div className="col-12 col-xs-12">
            <h3 className={styles.header}>{accountForm.Fiscal_Region[lang]}</h3>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-6 col-xs-12">
              {/* REGISTRATION ADDRESS COUNTRY */}
              <SelectInput
                testName={'countryRegistration'}
                testErrorName={'error-countryRegistration'}
                noOptionsMessage={accountForm.citizenshipInput.placeholder}
                lang={lang}
                disabled={!state.agreed}
                content={accountForm.countryRegistration}
                options={allFiscalCountry}
                onChange={id =>
                  dispatch({
                    type: actions.COUNTRY_REGISTRATION,
                    data: id,
                  })
                }
                valid={validateString(state.countryRegistration, 3, false)}
                value={state.countryRegistration}
                prefix={state.iframeCountry === '398' ? "Citizenships" : "CitizenshipsRf"}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xs-12">
            <h3 className={styles.header}>
              {accountForm.Fiscal_Requisites[lang]}
            </h3>
          </div>
        </div>
        <div className="row">
        {/* F_FiscalType */}
        <div className="col-8 col-xs-12">
          {state.F_FiscalType !== '1' &&
            <SelectInput
              lang={lang}
              noOptionsMessage={accountForm.noOptionsMessage}
              content={accountForm.F_FiscalType}
              onChange={data => {
                dispatch({
                  type: actions.F_FiscalType,
                  data,
                })
              }}
              value={state.F_FiscalType}
              options={
                state.countryRegistration === '398'
                  ? state.F_FiscalType === '1' ? IpTypeValuesKz : FiscalTypeValuesKz
                  : state.countryRegistration === '417'
                  ? FiscalTypeValuesKgz
                  : state.countryRegistration === '642'
                  ? FiscalTypeValuesRom
                  : FiscalTypeValues
              }
              valid={
                !invalidFields.includes('F_FiscalType') &&
                validateName(state.F_FiscalType)
              }
              className={styles.inputl}
            />
          }
          </div>
        </div>

        {state.F_FiscalType !== '1' ? (
          // Казахстан
          state.countryRegistration === '398' ? (
            <CompanyScreenOneKzt
              lang={lang}
              state={state}
              initialState={initialState}
              dispatch={dispatch}
              accountForm={accountForm}
              addressCountries={addressCountries}
              countriesList={countriesList}
              invalidFields={invalidFields}
              regionCity={regionCity}
              regionCityRegistered={regionCityRegistered}
              regionCityFactual={regionCityFactual}
            />
          ) : //Киргизия
          state.countryRegistration === '417' ||
            state.countryRegistration === '642' ? (
            <CompanyScreenOneKgz
              lang={lang}
              state={state}
              initialState={initialState}
              dispatch={dispatch}
              accountForm={accountForm}
              addressCountries={addressCountries}
              countriesList={countriesList}
              invalidFields={invalidFields}
              regionCity={regionCity}
              regionCityRegistered={regionCityRegistered}
              regionCityFactual={regionCityFactual}
            />
          ) : (
            <CompanyScreenOneRus
              lang={lang}
              state={state}
              initialState={initialState}
              dispatch={dispatch}
              accountForm={accountForm}
              addressCountries={addressCountries}
              countriesList={countriesList}
              invalidFields={invalidFields}
              regionCity={regionCity}
              regionCityRegistered={regionCityRegistered}
              regionCityFactual={regionCityFactual}
            />
          )
        ) : // Казахстан
        state.countryRegistration === '398' ? (
          <IpScreenOneKzt
            lang={lang}
            state={state}
            initialState={initialState}
            dispatch={dispatch}
            accountForm={accountForm}
            citizenshipCountriesList={citizenshipCountriesList}
            allFiscalCountry={allFiscalCountry}
            addressCountries={addressCountries}
            countriesList={countriesList}
            invalidFields={invalidFields}
            regionCity={regionCity}
            regionCityRegistered={regionCityRegistered}
            regionCityFactual={regionCityFactual}
          />
        ) : // Киргизия
        state.countryRegistration === '417' ? (
          <IpScreenOneKgz
            lang={lang}
            state={state}
            initialState={initialState}
            dispatch={dispatch}
            accountForm={accountForm}
            citizenshipCountriesList={citizenshipCountriesList}
            allFiscalCountry={allFiscalCountry}
            addressCountries={addressCountries}
            countriesList={countriesList}
            invalidFields={invalidFields}
            regionCity={regionCity}
            regionCityRegistered={regionCityRegistered}
            regionCityFactual={regionCityFactual}
          />
        ) : state.countryRegistration === '642' ? (
          <IpScreenOneRom
            lang={lang}
            state={state}
            initialState={initialState}
            dispatch={dispatch}
            accountForm={accountForm}
            citizenshipCountriesList={citizenshipCountriesList}
            allFiscalCountry={allFiscalCountry}
            addressCountries={addressCountries}
            countriesList={countriesList}
            invalidFields={invalidFields}
            regionCity={regionCity}
            regionCityRegistered={regionCityRegistered}
            regionCityFactual={regionCityFactual}
          />
        ) : (
          <IpScreenOneRus
            lang={lang}
            state={state}
            initialState={initialState}
            dispatch={dispatch}
            accountForm={accountForm}
            citizenshipCountriesList={citizenshipCountriesList}
            allFiscalCountry={allFiscalCountry}
            addressCountries={addressCountries}
            countriesList={countriesList}
            invalidFields={invalidFields}
            regionCity={regionCity}
            regionCityRegistered={regionCityRegistered}
            regionCityFactual={regionCityFactual}
          />
        )}
      </main>
    </>
  )
}

export default FiscalScreenOne
