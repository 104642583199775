import React, { useEffect, useRef, useState, useMemo } from 'react'

import TextInput from '../../../../components/Inputs/TextInput'
import SelectInput from '../../../../components/Inputs/SelectInput'
import FileInput from '../../../../components/Inputs/FileInput'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import {
  validateName,
  stringDigitsLength,
  validateString,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import warningImage from '../../../../images/warning.svg'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { getCompany } from '../../../../hooks/useCompanyCustomHook'
import AddressInputs from '../../AddressInputs/AddressInputs'
import { getFiscalFullName } from '../../../../utils/TranslateFiscalKgz/getFiscalFullName'
import { getFiscalShortName } from '../../../../utils/TranslateFiscalKgz/getFiscalShortName'
import { getCertificateRegistrationNumber } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationNumber'
import { getCertificateRegistrationDate } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationDate'
import { getCertificateRegistrationImage } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationImage'
import styles from '../../FiscalScreenOne.module.scss'
import { foundingDocumentOptions } from '../../../../content/foundingDocument'
import UrExist from '../../../../components/UrExist'

interface Props extends ScreenProps {
  countriesList: Country[]
  addressCountries: Country[]
  regionCity: any
  regionCityRegistered: any
  regionCityFactual: any
}

/* REACT COMPONENT */
function CompanyScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
}: Props) {
  const [focus, setFocus] = useState('')

  async function autoFill() {
    let response = await getCompany(state.F_INN),
      fullName = response.suggestions[0]?.data.name.full_with_opf,
      shortName = response.suggestions[0]?.data.name.short_with_opf,
      ogrn = response.suggestions[0]?.data.ogrn
    if (
      (fullName && !state.F_FullName) ||
      (fullName && state.F_FullName && !validateName(state.F_FullName, false))
    )
      dispatch({ type: actions.F_FullName, data: fullName })
    if (
      (shortName && !state.F_ShortName) ||
      (shortName &&
        state.F_ShortName &&
        !validateName(state.F_ShortName, false))
    )
      dispatch({ type: actions.F_ShortName, data: shortName })
    if (
      (ogrn && !state.F_OGRN) ||
      (ogrn && state.F_OGRN && !stringDigitsLength(state.F_OGRN, 13, false))
    )
      dispatch({ type: actions.F_OGRN, data: ogrn })
  }
  useEffect(() => {
    if (state.F_INN && stringDigitsLength(state.F_INN, 10, false)) autoFill()
  }, [state.F_INN])
  const fullNameObject = getFiscalFullName(
    state.F_FiscalType,
    state.countryRegistration
  )
  const shortNameObject = getFiscalShortName(
    state.F_FiscalType,
    state.countryRegistration
  )
  const certificateRegistrationNumber = getCertificateRegistrationNumber(
    state.F_FiscalType
  )
  const certificateRegistrationDate = getCertificateRegistrationDate(
    state.F_FiscalType
  )
  const certificateRegistrationImage = getCertificateRegistrationImage(
    state.F_FiscalType
  )
  const [shortName, setShortName] = useState(shortNameObject)
  const [fullName, setFullName] = useState(fullNameObject)

  useEffect(() => {
    setShortName(shortNameObject)
    setFullName(fullNameObject)
  }, [state.F_FiscalType])
  useEffect(() => {
    dispatch({
      type: actions.F_FiscalType,
      data: '7',
    })
  }, [state.countryRegistration])

  return (
    <>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-8 col-xs-12">
              {/* F_FullName */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={fullName}
                onChange={F_FullName =>
                  dispatch({ type: actions.F_FullName, data: F_FullName })
                }
                value={state.F_FullName || ''}
                valid={
                  state.F_FullName.length > 0
                    ? smartValidateString(state.F_FullName) &&
                      !invalidFields.includes('F_FullName')
                    : !invalidFields.includes('F_FullName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_FullName' || undefined}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* F_ShortName */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={shortName}
                onChange={F_ShortName =>
                  dispatch({ type: actions.F_ShortName, data: F_ShortName })
                }
                value={state.F_ShortName || ''}
                valid={
                  state.F_ShortName.length > 0
                    ? smartValidateString(state.F_ShortName) &&
                      !invalidFields.includes('F_ShortName')
                    : !invalidFields.includes('F_ShortName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_ShortName' || undefined}
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_INN}
                onChange={F_INN => {
                  dispatch({
                    type: actions.F_INN,
                    data: F_INN.replace(/\D/gim, '').substr(0, 14),
                  })
                }}
                // onChange={F_INN =>
                //   dispatch({ type: actions.F_INN, data: F_INN })
                // }
                value={state.F_INN || ''}
                required
                valid={
                  stringDigitsLength(state.F_INN, 14, false) &&
                  !invalidFields.includes('F_INN')
                }
                mask={'99999999999999'}
              />
            </div>
            {/* certificateRegistrationNumber */}
            <div className="col-5 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={certificateRegistrationNumber}
                onChange={certificateRegistrationNumber => {
                  dispatch({
                    type: actions.CERTIFICATE_REGISTRATION_NUMBER,
                    data: certificateRegistrationNumber,
                  })
                }}
                required
                value={state.certificateRegistrationNumber || ''}
                valid={
                  validateString(
                    state.certificateRegistrationNumber,
                    30,
                    false
                  ) && !invalidFields.includes('certificateRegistrationNumber')
                }
              />
            </div>
            <UrExist
              state={state}
              accountForm={accountForm}
              lang={lang}
              dispatch={dispatch}
              show={!state.isIdentityOk}
            />
            <div className="col-3 col-xs-12">
              <DatePicker
                lang={lang}
                accountForm={certificateRegistrationDate}
                accountFormKey="date"
                onChange={data => {
                  dispatch({
                    type: actions.REGISTERED_DATE,
                    data,
                  })
                }}
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 67)
                  )
                }
                max={new Date()}
                value={state.registeredDate}
                valid={!invalidFields.includes('registeredDate')}
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_RegisteredDocumentScan}
                onChange={stateRegistrationCertificate => {
                  dispatch({
                    type: actions.STATE_REGISTRATION_CERTIFICATE,
                    data: stateRegistrationCertificate,
                  })
                }}
                valid={!invalidFields.includes('stateRegistrationCertificate')}
                documentType="stateRegistrationCertificate"
                value={state.stateRegistrationCertificate[0]}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3 className={styles.header}>
                {accountForm.Fiscal_Current_Leader_Header[lang]}
              </h3>
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastname}
                onChange={(headLastname: string) =>
                  dispatch({
                    type: actions.HEAD_LASTNAME,
                    data: headLastname,
                  })
                }
                value={state.headLastname || ''}
                valid={
                  state.headLastname.length > 0
                    ? smartValidateString(state.headLastname) &&
                      !invalidFields.includes('headLastname')
                    : !invalidFields.includes('headLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstname}
                onChange={(headFirstname: string) =>
                  dispatch({
                    type: actions.HEAD_FIRSTNAME,
                    data: headFirstname,
                  })
                }
                value={state.headFirstname || ''}
                valid={
                  state.headFirstname.length > 0
                    ? smartValidateString(state.headFirstname) &&
                      !invalidFields.includes('headFirstname')
                    : !invalidFields.includes('headFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymic}
                onChange={(headPatronymic: string) =>
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: headPatronymic,
                  })
                }
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: '',
                  })
                }}
                value={state.headPatronymic || ''}
                valid={
                  state.headPatronymic.length > 0
                    ? smartValidateString(state.headPatronymic) &&
                      !invalidFields.includes('headPatronymic')
                    : !invalidFields.includes('headPatronymic')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastnameGenitive}
                onChange={(headLastnameGenitive: string) =>
                  dispatch({
                    type: actions.HEAD_LASTNAME_GENITIVE,
                    data: headLastnameGenitive,
                  })
                }
                value={state.headLastnameGenitive || ''}
                valid={
                  state.headLastnameGenitive.length > 0
                    ? smartValidateString(state.headLastnameGenitive) &&
                      !invalidFields.includes('headLastnameGenitive')
                    : !invalidFields.includes('headLastnameGenitive')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstnameGenitive}
                onChange={(headFirstnameGenitive: string) =>
                  dispatch({
                    type: actions.HEAD_FIRSTNAME_GENITIVE,
                    data: headFirstnameGenitive,
                  })
                }
                value={state.headFirstnameGenitive || ''}
                valid={
                  state.headFirstnameGenitive.length > 0
                    ? smartValidateString(state.headFirstnameGenitive) &&
                      !invalidFields.includes('headFirstnameGenitive')
                    : !invalidFields.includes('headFirstnameGenitive')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymicGenitive}
                onChange={(headPatronymicGenitive: string) =>
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: headPatronymicGenitive,
                  })
                }
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: '',
                  })
                }}
                value={state.headPatronymicGenitive || ''}
                valid={
                  state.headPatronymicGenitive.length > 0
                    ? smartValidateString(state.headPatronymicGenitive) &&
                      !invalidFields.includes('headPatronymicGenitive')
                    : !invalidFields.includes('headPatronymicGenitive')
                }
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Genitive[lang]}
                <span>
                  {' '}
                  {accountForm.Fiscal_Warning_Genitive_Example[lang]}
                </span>
              </p>
            </div>
            <div className="col-4 col-xs-12">
              <SelectInput
                testName={'foundingDocument'}
                testErrorName={'error-foundingDocument'}
                lang={lang}
                noOptionsMessage={accountForm.foundingDocument.placeholder}
                content={accountForm.foundingDocument}
                onChange={data => {
                  dispatch({
                    type: actions.FOUNDING_DOCUMENT,
                    data,
                  })
                }}
                value={state.foundingDocument}
                options={foundingDocumentOptions}
                valid={
                  !invalidFields.includes('foundingDocument') &&
                  validateString(state.foundingDocument, 100)
                }
                className={styles.inputl}
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headPosition}
                onChange={(headPosition: string) =>
                  dispatch({
                    type: actions.HEADPOSITION,
                    data: headPosition,
                  })
                }
                value={state.headPosition || ''}
                valid={
                  state.headPosition.length > 0
                    ? smartValidateString(state.headPosition) &&
                      !invalidFields.includes('headPosition')
                    : !invalidFields.includes('headPosition')
                }
              />
            </div>
            {lang !== 'eng' && (
              <div className="col-4 col-xs-12">
                <TextInput
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.headPositionGenitive}
                  onChange={(headPositionGenitive: string) =>
                    dispatch({
                      type: actions.HEADPOSITION_GENITIVE,
                      data: headPositionGenitive,
                    })
                  }
                  value={state.headPositionGenitive || ''}
                  valid={
                    state.headPositionGenitive.length > 0
                      ? smartValidateString(state.headPositionGenitive) &&
                        !invalidFields.includes('headPositionGenitive')
                      : !invalidFields.includes('headPositionGenitive')
                  }
                />
              </div>
            )}
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Scan_Directors_Passport}
                onChange={data => {
                  dispatch({
                    type: actions.F_SCAN_DIRECTORS_PASSPORT,
                    data: data,
                  })
                }}
                valid={!invalidFields.includes('F_Scan_Directors_Passport')}
                documentType="Passport1"
                value={
                  state.F_Scan_Directors_Passport[
                    state.F_Scan_Directors_Passport.length - 1
                  ]
                }
              />
            </div>
            <div className="col-8 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.foundingDocument_Image}
                onChange={data => {
                  dispatch({
                    type: actions.FOUNDING_DOCUMENT_IMAGE_FILE,
                    data: data,
                  })
                }}
                valid={!invalidFields.includes('foundingDocument_Image')}
                documentType="FoundingDocument"
                value={
                  state.foundingDocument_Image[
                    state.foundingDocument_Image.length - 1
                  ]
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Scan_Directors_Passport_Address}
                onChange={data => {
                  dispatch({
                    type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                    data: data,
                  })
                }}
                valid={
                  !invalidFields.includes('F_Scan_Directors_Passport_Address')
                }
                documentType="Passport2"
                value={
                  state.F_Scan_Directors_Passport_Address[
                    state.F_Scan_Directors_Passport_Address.length - 1
                  ]
                }
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Scan_Directors_Passport[lang]}
              </p>
            </div>
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
        />
      </div>
    </>
  )
}

export default CompanyScreenOne
